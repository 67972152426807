import React from "react"
import "../components/css/page/movie-temp.css"

class MovieTemp extends React.Component {

  render () {
    return (
      <div className="movie-temp">
        <div>
          <h1>
            <span role='img' aria-label="book">🎥</span> 영상이 준비중입니다.</h1>
          <p>
            몇 주 내로 영상을 마무리하여
            <br />
            업로드하도록 하겠습니다.
            <br />
            <br />
            조금만 기다려주세요!
          </p>
        </div>
      </div>
    )
  }
}

export default MovieTemp